/**
 * A stimulus controller mixin to enable / disable a target element.
 * Exposes `#setElementEnabled(element, isEnabled: boolean = true)`
 *
 * @param controller - Stimulus controller to attach this mixin.
 */
export const useElementEnabled = (controller) => {
  Object.assign(controller, {
    /**
     * Whether or not to set the {@link element} to be enabled.
     *
     * @param {Element?} element - The element to change its enabled state
     * @param {boolean} isEnabled - True (default) to make the element enabled, otherwise disable it.
     */
    setElementEnabled(element, isEnabled = true) {
      if (!element) {
        // Do nothing if element is nullish
        return;
      }

      element.disabled = !isEnabled;

      if (isEnabled) {
        element.classList.remove('disabled');
      } else {
        element.classList.add('disabled');
      }
    },
  });
};
