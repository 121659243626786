import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

// Connects to data-controller="dashboard-filter"
export default class extends Controller {
  static values = {
    defaultStartDate: String, // in iso8601 format
    defaultEndDate: String, // in iso8601 format
  };

  static targets = [
    'venuesSelect',
    'facilitiesSelect',
    'facilityTypesSelect',
    'activityCategoriesSelect',
    'customersSelect',
  ];

  // Types
  declare defaultStartDateValue: string;
  declare defaultEndDateValue: string;
  declare readonly venuesSelectTarget: HTMLSelectElement;
  declare readonly facilitiesSelectTarget: HTMLSelectElement;
  declare readonly facilityTypesSelectTarget: HTMLSelectElement;
  declare readonly activityCategoriesSelectTarget: HTMLSelectElement;
  declare readonly customersSelectTarget: HTMLSelectElement;

  private selectedFilters = {
    startDate: null,
    endDate: null,
    venueIds: [],
    facilityIds: [],
    facilityTypeIds: [],
    activityCategoryIds: [],
    customerIds: [],
    compare: false,
  };

  connect() {
    super.connect();

    // Setup all filters
    this.setup();

    // Remember the default filters
    this.rememberSelectedFilters();
  }

  public dateRangeChanged(event: CustomEvent) {
    this.selectedFilters.startDate = event.detail.selectedStartDate as Date;
    this.selectedFilters.endDate = event.detail.selectedEndDate as Date;
    this.selectedFilters.compare = event.detail.compare as boolean;

    this.notifyGraphsOnFilterChange();
  }

  private setup() {
    const venuesSelectOptions = {
      width: 'auto',
      dropdownAutoWidth: true,
      ajax: {
        url: this.venuesSelectTarget.dataset.optionsPath,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults: (response: any, params) => {
          let results = response.data.map((resource) => {
            return {
              id: resource.id,
              text: resource.attributes.text || resource.attributes.name,
            };
          });

          // Add default option 'all'
          if (params._type !== 'query:append') {
            const allVenuesTitle =
              this.venuesSelectTarget.dataset.followingVenues === 'true'
                ? 'All followed venues'
                : 'All venues';

            results = [{ id: 'all', text: allVenuesTitle }, ...results];
          }

          return {
            results: results,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
    };

    $(this.venuesSelectTarget)
      .select2(venuesSelectOptions)
      .on('change', (e) => {
        const selectedVenueId = e.currentTarget.value;
        this.selectedFilters.venueIds = [selectedVenueId];

        this.notifyGraphsOnFilterChange();
      });

    const facilitiesSelectOptions = {
      width: 'auto',
      dropdownAutoWidth: true,
      ajax: {
        url: this.facilitiesSelectTarget.dataset.optionsPath,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          const selectedVenueId = this.selectedFilters.venueIds[0];

          return {
            q: params.term,
            page: params.page,
            venue_id: selectedVenueId !== 'all' ? selectedVenueId : undefined,
          };
        },
        processResults: (response: any, params) => {
          let results = response.data.map((resource) => {
            return {
              id: resource.id,
              text: resource.attributes.text || resource.attributes.name,
            };
          });

          // Add default option 'all'
          if (params._type !== 'query:append') {
            results = [{ id: 'all', text: 'All facilities' }, ...results];
          }

          return {
            results: results,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
    };

    $(this.facilitiesSelectTarget)
      .select2(facilitiesSelectOptions)
      .on('change', (e) => {
        const selectedFacilityId = e.currentTarget.value;
        this.selectedFilters.facilityIds = [selectedFacilityId];

        this.notifyGraphsOnFilterChange();
      });

    const facilityTypesSelectOptions = {
      width: 'auto',
      dropdownAutoWidth: true,
      ajax: {
        url: this.facilityTypesSelectTarget.dataset.optionsPath,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults: (response: any, params) => {
          let results = response.data.map((resource) => {
            return {
              id: resource.id,
              text: resource.attributes.text || resource.attributes.name,
            };
          });

          // Add default option 'all'
          if (params._type !== 'query:append') {
            results = [{ id: 'all', text: 'All facility types' }, ...results];
          }

          return {
            results: results,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
    };

    $(this.facilityTypesSelectTarget)
      .select2(facilityTypesSelectOptions)
      .on('change', (e) => {
        const selectedFacilityTypeId = e.currentTarget.value;
        this.selectedFilters.facilityTypeIds = [selectedFacilityTypeId];

        this.notifyGraphsOnFilterChange();
      });

    const activityCategoriesSelectOptions = {
      width: 'auto',
      dropdownAutoWidth: true,
      ajax: {
        url: this.activityCategoriesSelectTarget.dataset.optionsPath,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults: (response: any, params) => {
          let results = response.data.map((resource) => {
            return {
              id: resource.id,
              text: resource.attributes.text || resource.attributes.name,
            };
          });

          // Add default option 'all'
          if (params._type !== 'query:append') {
            results = [{ id: 'all', text: 'All activities' }, ...results];
          }

          return {
            results: results,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
    };

    $(this.activityCategoriesSelectTarget)
      .select2(activityCategoriesSelectOptions)
      .on('change', (e) => {
        const selectedActivityCategoryId = e.currentTarget.value;
        this.selectedFilters.activityCategoryIds = [selectedActivityCategoryId];

        this.notifyGraphsOnFilterChange();
      });

    //-

    const customersSelectOptions = {
      width: 'auto',
      dropdownAutoWidth: true,
      ajax: {
        url: this.customersSelectTarget.dataset.optionsPath,
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults: (response: any, params) => {
          let results = response.data.map((resource) => {
            return {
              id: resource.id,
              text: resource.attributes.text || resource.attributes.name,
            };
          });

          // Add default option 'all'
          if (params._type !== 'query:append') {
            results = [{ id: 'all', text: 'All customers' }, ...results];
          }

          return {
            results: results,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
    };

    $(this.customersSelectTarget)
      .select2(customersSelectOptions)
      .on('change', (e) => {
        const selectedCustomerId = e.currentTarget.value;
        this.selectedFilters.customerIds = [selectedCustomerId];

        this.notifyGraphsOnFilterChange();
      });
  }

  private notifyGraphsOnFilterChange() {
    this.selectedFilters.startDate ||= new Date(this.defaultStartDateValue);
    this.selectedFilters.endDate ||= new Date(this.defaultEndDateValue);

    this.dispatch('filter-changed', {
      detail: {
        selectedFilters: this.selectedFilters,
      },
    });
  }

  private rememberSelectedFilters() {
    this.selectedFilters.startDate ||= new Date(this.defaultStartDateValue);
    this.selectedFilters.endDate ||= new Date(this.defaultEndDateValue);

    this.dispatch('remember-filters', {
      detail: {
        selectedFilters: this.selectedFilters,
      },
    });
  }
}
