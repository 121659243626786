import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    showPath: String,
  };

  refresh(e) {
    const form = $(e.currentTarget).parents('form').get(0);
    const formData = new FormData(form);
    const params = new URLSearchParams(formData);

    this.element.src = this.showPathValue + '?' + params;
  }
}
