import { Controller } from '@hotwired/stimulus';
import moment from 'moment';

// Connects to data-controller="dashboard-date-filter"
export default class extends Controller {
  static values = {
    defaultStartDate: String, // in iso8601 format
    defaultEndDate: String, // in iso8601 format
  };

  static targets = [
    'button',
    'startDateLabel',
    'endDateLabel',

    'modal',
    'startDateInput',
    'endDateInput',

    'compareCheckbox',

    'applyButton',
    'resetButton',

    'presetDateRangeButton',
  ];

  // Types
  declare defaultStartDateValue: string;
  declare defaultEndDateValue: string;
  declare readonly buttonTarget: HTMLButtonElement;
  declare readonly startDateInputTarget: HTMLInputElement;
  declare readonly startDateLabelTarget: HTMLSpanElement;
  declare readonly endDateInputTarget: HTMLInputElement;
  declare readonly endDateLabelTarget: HTMLSpanElement;
  declare readonly modalTarget: HTMLDivElement;
  declare readonly compareCheckboxTarget: HTMLInputElement;
  declare readonly applyButtonTarget: HTMLButtonElement;
  declare readonly resetButtonTarget: HTMLButtonElement;

  declare readonly presetDateRangeButtonTargets: HTMLInputElement[];

  // Custom properties and methods
  private selectedStartDate: moment.Moment = this.getDefaultStartDate();
  private selectedEndDate: moment.Moment = this.getDefaultEndDate();
  private compare: boolean = false;

  connect() {
    super.connect();

    this.setupEventListeners();

    this.updateSelectedDateInputs(); // will use the default range initially
    this.updateSelectedDateLabels();
  }

  private setupEventListeners() {
    const handleDateChange = (e: Event) => {
      const element = e.target as HTMLInputElement;
      const newDate = element.value;

      if (newDate === '') {
        // Cleared this date input, so reset the selected start and end dates:
        this.resetDateInputValues();
      }
    };

    this.startDateInputTarget.addEventListener('change', handleDateChange);
    this.endDateInputTarget.addEventListener('change', handleDateChange);

    // Setup preset date range selection button
    this.presetDateRangeButtonTargets.forEach((target) => {
      target.addEventListener('click', (e: Event) => {
        const element = e.target as HTMLInputElement;
        const preset = element.dataset.preset;

        // Uncheck the previously selected preset (if any), ensuring that
        // only one preset is selected at a time if any are selected.
        this.presetDateRangeButtonTargets
          .filter((target) => target.id !== element.id && target.checked)
          .forEach((target) => (target.checked = false));

        // When unselecting a preset on this date modal opening session, revert
        // to the date it was set previously when the modal was first opened.
        if (!element.checked) {
          this.resetDateInputValues();
          return;
        }

        switch (preset) {
          case 'today':
            this.selectedStartDate = moment().startOf('day');
            this.selectedEndDate = moment().endOf('day');
            break;

          case 'thisWeek':
            this.selectedStartDate = moment().startOf('week');
            this.selectedEndDate = moment().endOf('week');
            break;

          case 'lastWeek':
            this.selectedStartDate = moment()
              .subtract(1, 'week')
              .startOf('day');
            this.selectedEndDate = moment().endOf('day');
            break;

          case 'thisMonth':
            this.selectedStartDate = moment().startOf('month');
            this.selectedEndDate = moment().endOf('month');
            break;

          case 'lastMonth':
            this.selectedStartDate = moment()
              .subtract(1, 'month')
              .startOf('month');
            this.selectedEndDate = this.selectedStartDate
              .clone()
              .endOf('month');
            break;

          case 'last30Days':
            this.selectedStartDate = moment()
              .subtract(30, 'days')
              .startOf('day');
            this.selectedEndDate = moment().endOf('day');
            break;

          case 'last90Days':
            this.selectedStartDate = moment()
              .subtract(90, 'days')
              .startOf('day');
            this.selectedEndDate = moment().endOf('day');
            break;

          case 'thisYear':
            this.selectedStartDate = moment().startOf('year');
            this.selectedEndDate = moment().endOf('year');
            break;

          case 'lastYear':
            this.selectedStartDate = moment()
              .subtract(1, 'year')
              .startOf('year');
            this.selectedEndDate = this.selectedStartDate.clone().endOf('year');
            break;
        }

        this.updateSelectedDateInputs();
      });
    });

    // Setup the compare checkbox to maintain its last applied state after modal close
    this.modalTarget.addEventListener('hidden.bs.modal', () => {
      this.compareCheckboxTarget.checked = this.compare;
    });

    // Setup apply button
    this.applyButtonTarget.addEventListener('click', () => {
      this.selectedStartDate = moment(this.startDateInputTarget.value).startOf(
        'day'
      );
      this.selectedEndDate = moment(this.endDateInputTarget.value).endOf('day');
      this.compare = this.compareCheckboxTarget.checked;

      this.updateSelectedDateLabels();
      this.notifyDateRangeFilterChanged();
    });

    // Setup reset button
    this.resetButtonTarget.addEventListener('click', () => {
      this.resetDateInputValues();

      this.selectedStartDate = moment(this.startDateInputTarget.value).startOf(
        'day'
      );
      this.selectedEndDate = moment(this.endDateInputTarget.value).endOf('day');
      this.compare = false;

      this.updateSelectedDateLabels();
      this.notifyDateRangeFilterChanged();
    });
  }

  private resetDateInputValues(): void {
    this.startDateInputTarget.value =
      this.getDefaultStartDate().format('YYYY-MM-DD');
    this.endDateInputTarget.value =
      this.getDefaultEndDate().format('YYYY-MM-DD');
  }

  private updateSelectedDateInputs(): void {
    this.startDateInputTarget.value =
      this.selectedStartDate.format('YYYY-MM-DD');
    this.endDateInputTarget.value = this.selectedEndDate.format('YYYY-MM-DD');
  }

  private updateSelectedDateLabels(): void {
    // Update the filter button label
    this.startDateLabelTarget.innerHTML =
      this.selectedStartDate.format('Do MMM YYYY');
    this.endDateLabelTarget.innerHTML =
      this.selectedEndDate.format('Do MMM YYYY');

    this.endDateInputTarget.setAttribute(
      'min',
      this.selectedStartDate.format('YYYY-MM-DD')
    );
  }

  private getDefaultStartDate(): moment.Moment {
    return moment(this.defaultStartDateValue);
  }

  private getDefaultEndDate(): moment.Moment {
    return moment(this.defaultEndDateValue);
  }

  private notifyDateRangeFilterChanged() {
    this.dispatch('date-range-filter-changed', {
      detail: {
        selectedStartDate: this.selectedStartDate.toDate(),
        selectedEndDate: this.selectedEndDate.toDate(),
        compare: this.compare,
      },
    });
  }
}
