import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="gtag"
export default class extends Controller {
  static values = {
    eventName: String,
    eventParams: Object,
  };

  event(e) {
    if (typeof window.gtag === 'function') {
      try {
        const { event, params } = JSON.parse(
          e.currentTarget.dataset.gtagParameters
        );
        if (event === undefined || event.trim() === '') {
          console.error('Error: event name is required for gtag');
        }
        window.gtag('event', event, params);
      } catch (error) {
        console.error('Error parsing gtag JSON:', error.message);
      }
    }
  }
}
