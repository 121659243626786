import $ from 'jquery';
import DatePicker from 'tui-date-picker';
import SearchController from './search_controller';

// Connects to data-controller="booking-filter-modal"
export default class extends SearchController {
  static targets = [
    'fromInput',
    'toInput',
    'multiSelectInput',
    'venueInput',
    'facilityInput',
  ];

  initialize() {
    super.initialize();

    this.fromTimePicker = this.initTimePicker(this.fromInputTarget);
    this.toTimePicker = this.initTimePicker(this.toInputTarget);

    this.multiSelectInputTargets.forEach((target) =>
      this.initMultiSelect(target)
    );

    $(this.venueInputTarget).on(
      'select2:select select2:unselect select2:clear',
      function () {
        const event = new Event('change', { bubbles: true });
        this.dispatchEvent(event);
      }
    );

    this.onVenueChange();

    this.fromTimePicker.on('draw', () => {
      this.preventTouchEventsSubmittingParentForm();
    });

    this.toTimePicker.on('draw', () => {
      this.preventTouchEventsSubmittingParentForm();
    });
  }

  initTimePicker(target) {
    return new DatePicker($(target).data('container'), {
      ...(target.value && { date: Date.parse(target.value) }),
      input: {
        element: target,
        format: 'D, dd MMM YYYY hh:mm',
      },
      timePicker: {
        showMeridiem: false,
      },
      weekStartDay: 'Mon',
    });
  }

  initMultiSelect(target) {
    $(target).select2({
      placeholder: $(target).attr('placeholder'),
      closeOnSelect: false,
    });
  }

  onReset() {
    $(this.element).find('input:text, input[type="number"]').val('');

    $(this.element).find('input:checkbox').prop('checked', false);

    $(this.multiSelectInputTargets).val('').trigger('change');

    this.submit();
  }

  onVenueChange() {
    const rawFacilityOptions = $(this.facilityInputTarget).data('options');
    const venueOptions = $(this.venueInputTarget).select2('data');
    const venueIds = venueOptions.map((v) => parseInt(v.id));

    const filteredFacilityOptions = venueIds.length
      ? rawFacilityOptions.filter((f) => venueIds.includes(f.venue_id))
      : rawFacilityOptions;

    const filteredFacilities = filteredFacilityOptions.map((f) => ({
      id: f.id,
      text: f.name,
    }));

    this.updateFacilityOptions(filteredFacilities);
  }

  updateFacilityOptions(filteredFacilities) {
    this.clearUnselectedOptions();

    $(this.facilityInputTarget).select2({
      data: filteredFacilities,
      placeholder: 'Select facilities',
    });
  }

  clearUnselectedOptions() {
    const selectedValues = $(this.facilityInputTarget).val();

    $(this.facilityInputTarget)
      .find('option')
      .each(function () {
        const optionValue = $(this).val();
        if (!selectedValues || selectedValues.indexOf(optionValue) === -1) {
          $(this).remove();
        }
      });

    $(this.facilityInputTarget).trigger('change');
  }

  preventTouchEventsSubmittingParentForm = () => {
    const btns = document.querySelectorAll('.tui-calendar-btn');
    btns.forEach((btn) => {
      btn.addEventListener('touchend', (event) => {
        event.preventDefault(); // Prevent the form from submitting
      });
    });
  };
}
